import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
      <path fill="currentColor" class="st1" d="M41.69,29.53c-0.55,0.95-1.07,1.82-1.58,2.73c-0.79,1.35-1.54,2.73-2.33,4.08c-0.67,1.15-1.35,2.3-2.06,3.48
			c-0.12-0.16-0.16-0.28-0.24-0.4c-1.9-3.21-3.8-6.41-5.66-9.62c-0.12-0.2-0.24-0.36-0.51-0.36c-1.42,0.04-2.89,0-4.31,0
			c-2.22,0-4.39,0-6.65,0c0.04,0.12,0.08,0.2,0.12,0.24c1.39,2.33,2.73,4.67,4.12,7c0.71,1.23,1.46,2.45,2.22,3.68
			c0.83,1.42,1.66,2.85,2.49,4.27c0.16,0.28,0.16,0.44,0,0.71c-0.71,1.19-1.39,2.37-2.1,3.56c-0.08,0.08-0.16,0.16-0.2,0.28
			c-1.86,3.13-3.72,6.21-5.54,9.3c-0.4,0.67-0.79,1.35-1.19,2.06c0.2,0,0.32,0,0.44,0c1.9,0,3.8-0.04,5.7,0
			c0.59,0,0.91-0.16,1.11-0.67c0.04-0.08,0.08-0.12,0.16-0.2c3.13-5.26,6.21-10.53,9.34-15.79c0.47-0.79,0.95-1.62,1.42-2.41
			c0.04-0.08,0.08-0.32,0.28-0.2c0.16,0.08,0.04,0.24,0,0.36c-0.04,0.04-0.04,0.08-0.08,0.12c-3.48,5.86-6.93,11.75-10.41,17.61
			c-0.24,0.36-0.47,0.71-0.63,1.15c0.36,0.08,0.63,0.04,0.83-0.32c2.61-4.47,5.26-8.94,7.87-13.41c0.87-1.46,1.74-2.97,2.61-4.43
			c0.08-0.12,0.12-0.44,0.4-0.12c0.08,0.12,0.12,0.24,0.2,0.36c-3.48,5.86-6.93,11.71-10.41,17.61c-0.04,0.12-0.2,0.2-0.08,0.36
			c2.93,0,5.82,0,8.75,0c0.32,0,0.4-0.16,0.51-0.36c1.27-2.18,2.53-4.35,3.8-6.53c1.11-1.94,2.22-3.84,3.36-5.78
			c0.12-0.2,0.2-0.36,0.4-0.12c0.04,0.08,0.04,0.12,0.08,0.2c2.37,4.12,4.75,8.19,7.12,12.31c0.12,0.24,0.28,0.28,0.55,0.28
			c2.49,0,4.99,0,7.48,0c0.16,0,0.36,0,0.67,0C53.52,50.22,47.98,39.66,41.69,29.53z"/>
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
